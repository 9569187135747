<template>
  <div
    v-if="suspense && suspense.suspendShow && iconList && iconList.length"
    class="suspense"
  >
    <div class="icon_preview_content">
      <div
        class="icon"
        :style="{ 'background-color': cartIcon.isHover ? suspense.selectBackgroundColor : '#fff' }"
        @mouseover="cartIcon.isHover = true"
        @mouseout="cartIcon.isHover = false"
        @click="goto(cartIcon.targetUrl)"
      >
        <img
          v-if="!cartIcon.isHover"
          src="@/assets/images/new/gwc@2x.png"
          alt=""
        >
        <img
          v-if="cartIcon.isHover"
          src="@/assets/images/new/gwc1@2x.png"
          alt=""
        >
        <div
          class="icon_name"
          :style="{ color: cartIcon.isHover ? suspense.selectTextColor : 'inherit' }"
        >
          {{ cartIcon.iconName }}
        </div>
      </div>
    </div>
    <div
      v-for="(item,index) in iconList"
      :key="index"
      class="icon_preview_content"
    >
      <div
        v-if="item.suspendIconType === 2"
        class="icon"
        :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
        @mouseenter="item.isHover = true"
        @mouseleave="item.isHover = false"
        @click="toTop"
      >
        <img
          v-if=" item.defaultIcon && !item.isHover"
          :src="item.defaultIcon"
          alt=""
        >
        <img
          v-if="item.selectIcon && item.isHover"
          :src="item.selectIcon"
          alt=""
        >
        <div
          v-if="item.showIconName"
          class="icon_name"
          :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
        >
          {{ item.iconName }}
        </div>
      </div>
      <client-only>
        <el-popover
          v-if="item.suspendIconType === 1 && item.suspendShowStyle !== 3"
          placement="left"
          trigger="hover"
          @show="item.isHover = true"
          @hide="item.isHover = false"
        >
          <template #reference>
            <div
              class="icon"
              :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
            >
              <img
                v-if=" item.defaultIcon && !item.isHover"
                :src="item.defaultIcon"
                alt=""
              >
              <img
                v-if="item.selectIcon && item.isHover"
                :src="item.selectIcon"
                alt=""
              >
              <div
                v-if="item.showIconName"
                class="icon_name"
                :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
              >
                {{ item.iconName }}
              </div>
            </div>
          </template>
          <template v-if="item.suspendShowStyle === 1">
            <img
              class="icon_popover_img"
              :src="item.targetUrl"
              alt=""
            >
            <div class="popover_targetDesc">
              {{ item.targetDesc }}
            </div>
          </template>
          <template v-if="item.suspendShowStyle === 2">
            <div v-html="item.targetDesc"></div>
          </template>
        </el-popover>
      </client-only>
      <a
        v-if="item.suspendShowStyle === 3 && item.iconName !== '查验证书'&& item.iconName !== '在线咨询'"
        class="icon"
        :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
        :href="item.targetUrl"
        target="_blank"
        @mouseover="item.isHover = true"
        @mouseout="item.isHover = false"
      >
        <img
          v-if="item.defaultIcon && !item.isHover"
          :src="item.defaultIcon"
          alt=""
        >
        <img
          v-if="item.selectIcon && item.isHover"
          :src="item.selectIcon"
          alt=""
        >
        <div
          v-if="item.showIconName"
          class="icon_name"
          :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
        >
          {{ item.iconName }}
        </div>
      </a>
      <div
        v-if="item.suspendShowStyle === 3 && item.iconName === '查验证书'"
        class="icon"
        :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
        @mouseover="item.isHover = true"
        @mouseout="item.isHover = false"
        @click="gitto(item.targetUrl)"
      >
        <img
          v-if="item.defaultIcon && !item.isHover"
          :src="item.defaultIcon"
          alt=""
        >
        <img
          v-if="item.selectIcon && item.isHover"
          :src="item.selectIcon"
          alt=""
        >
        <div
          v-if="item.showIconName"
          class="icon_name"
          :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
        >
          {{ item.iconName }}
        </div>
      </div>
      <div
        v-if="item.suspendShowStyle === 3 && item.iconName === '在线咨询'"
        class="icon"
        :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
        @mouseover="item.isHover = true"
        @mouseout="item.isHover = false"
        @click="gitkf(item)"
      >
        <img
          v-if="item.defaultIcon && !item.isHover"
          :src="item.defaultIcon"
          alt=""
        >
        <img
          v-if="item.selectIcon && item.isHover"
          :src="item.selectIcon"
          alt=""
        >
        <div
          v-if="item.showIconName"
          class="icon_name"
          :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
        >
          {{ item.iconName }}
        </div>
      </div>
    </div>

    <!-- <el-dialog
      v-model="dialogTableVisible"
      title="在线咨询"
      append-to-body
      width="800px"
      @close="closeDialog"
    >
      <div
        v-if="dialogTableVisible"
        style="width: 100%;height: 380px;"
      >
        <iframe
          width="100%"
          style="height: 100% !important;"
          :src="iframeSre"
          frameborder="0"
        />
      </div>
    </el-dialog> -->
    <div
      v-show="dialogTableVisible"
      ref="kefuRef"
      class="el-overlay"
      style="z-index: 99999;"
    >
      <div
        v-if="dialogTableVisible"
        class="dialogBody"
      >
        <iframe
          width="100%"
          style="height: 100% !important;"
          :src="iframeSre"
          frameborder="0"
        />
        <!-- <div class="closeBox">
          <el-button
            round
            @click="closeDialog"
          >
            关闭对话框
          </el-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { computed, ref, onMounted, reactive, nextTick } from 'vue'
import { useLayoutStore } from '@/stores/layout'
import { useUserStore } from '@/stores/user'
import { shopDataInfo, testDebug } from '../../api/system'
import config from '~/config'
const { data: shopData } = await useAsyncData('course', async (ctx) => {
  let url = ''
  if (process.server) {
    url = ctx.ssrContext?.event?.req?.headers?.host
  } else {
    url = window.location.host
  }
  if (config.url) {
    url = config.url
  }
  return await shopDataInfo({ domain: url })
})
const layoutStore = useLayoutStore()
const suspense = computed(() => layoutStore.suspense)
const iconList = computed(() => {
  return suspense.value.iconList.filter((item) => {
    return item
  })
})
const kefuRef = ref()
const userStore = useUserStore()
const { getToken, getUserView } = userStore
const router = useRouter()
const route = useRoute()
const dialogTableVisible = ref(false)
const iframeSre = ref('')
const cartIcon = ref({
  isHover: false,
  targetUrl: '/shopping/trolley',
  iconName: '购物车'
})

const goto = (url) => {
  if (getToken()) {
    router.push({
      path: url
    })
  } else {
    router.push({
      path: '/login',
      query: {
        redirect: encodeURIComponent(route.fullPath)
      }
    })
  }
}
const gitkf = async (item) => {
  if (getToken()) {
    const ustomerLink = shopData.value.ustomerLink.split('otherParams=')
    const userData = await getUserView()
    const clientId = userData.id
    const nickName = userData.nickname
    let otherParams = JSON.parse(ustomerLink[1])
    otherParams.nickName = nickName
    otherParams = JSON.stringify(otherParams)
    iframeSre.value = ustomerLink[0] + 'clientId=' + clientId + '&otherParams=' + otherParams
  } else {
    iframeSre.value = shopData.value.ustomerLink
  }
  window.open(iframeSre.value)
}
const closeDialog = () => {
  dialogTableVisible.value = false
}
const toTop = () => {
  document.querySelector('#app').scrollTop = 0
}
const gitto = (url) => {
  router.push({
    path: '/certificate/look'
  })
}
onMounted(() => {
  layoutStore.setSuspense()
  // nextTick(() => {
  //   // document.querySelector('body').appendChild(kefuRef.value)
  //   document.body.appendChild(kefuRef.value)
  // })
  // document.body.appendChild(kefuRef.value)
})
</script>

<style lang="scss" scoped>
:deep(.el-dialog .el-dialog__body){
 padding-top: 0 !important;
}
.no-header-dialog .el-dialog__header {
  display: none;
}
.dialogBody{
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 300px);
  width: 600px;
  height: 500px;
  .closeBox{
    bottom: -50px;
    position: absolute;
    margin: 0 auto;
  }
}
.kefu{
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  z-index: 9999999;
  background: #ffffff;
}
.suspense {
  padding: 5px 0;
  overflow: hidden;
  z-index: 100;
  position: fixed;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 60px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(54, 41, 183, 0.06);
  border-radius: 5px;
}
.icon_preview_content {
  cursor: pointer;
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    transition: all 0.3s;
    img {
      width: 24px;
      height: 24px;
      transition: all 0.3s;
    }
    .icon_name {
      margin-top: 2px;
      font-size: 12px;
      line-height: 18px;
      transition: all 0.3s;
    }
    &:hover {
      background: rgb(245, 166, 35);
      color: rgb(189, 16, 224);
    }
  }
}
</style>
